<template>
    <div>
        <radar-e-charts
            v-if="value"
            ref="radar"
            autoresize
            :options="option"
            :initOptions="initOptions"
            theme="theme-color"
            @chartInitialized = "handleChild"
            auto-resize
        />
    </div>
</template>
  
<script>
  import RadarECharts from '@core/components/charts/echart/RadarEChart.vue'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/title'
  import 'echarts/lib/component/legend'
  import 'echarts/lib/chart/radar'
  import theme from '@core/components/charts/echart/theme.json'
  import Functions from "@/Constants/Functions"

  RadarECharts.registerTheme('theme-color', theme)
  
  export default {
    components: {
      RadarECharts,
    },
    props: {
      value: {
        type:Array
      },
      indicator:{
        type:Array
      }
    },
    data() {
      return {
        option: {
          legend: {
            data: [this.value[0].name, this.value[1].name]
          },
          tooltip: {
            formatter: function(params) {
              console.log("here", this.globalIndexId, this.globalIndicators[this.globalIndexId]/10)
              if (this.globalIndexId === undefined)
                return
              let value = params.value[this.globalIndexId]
              // return params.marker
              return Functions.getGrade(this.globalIndicators[this.globalIndexId]/10)
              // return "ASd"
            }.bind(this)
          },
          radar: {
            name: {
              textStyle: {
                color: '#626262',
                fontWeight: 'bold',
                borderRadius: 3,
                padding: [3, 5],
              },
            },
            indicator: this.indicator,
            splitArea: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: [
                  '#bbb',
                ],
              },
            },
            axisLine: {
              lineStyle: {
                color: '#ccc',
              },
            },
          },
          // toolbox: {
          //     show: true,
          //     feature: {
          //     mark: { show: true },
          //     dataView: { show: true, readOnly: false },
          //     restore: { show: true },
          //     saveAsImage: { show: true }
          //     }
          // },
          series: [
            {
              name: 'Radar Chart',
              type: 'radar',
              data: this.value
            }
          ]
        },
        initOptions:{ 'renderer': 'svg' },
        myChart:null,
        globalIndexId:null, 
        globalIndicators: new Array()
      }
    },
    methods:{
      handleChild(chart){
        this.myChart = chart;
        this.$nextTick(() => {
          this.setIndicatorEvent();
        });
      },
      setIndicatorEvent() {
        // console.log("document.querySelectorAll('#RadarChart')",document.querySelectorAll('#RadarChart')) 
        let tags = document.querySelectorAll('#RadarChart path[transform]');
        for (let i = 0; i < tags.length; i++) {
          if (tags[i].attributes?.transform.value.includes("matrix"))
          // console.log("tags[i]", tags[i])
            tags[i].addEventListener('mouseover', this.mouseOverEvent);
            tags[i].addEventListener('mouseout', this.mouseOutEvent);
        }
      },
      mouseOverEvent(event) {
        // console.log("event", event.currentTarget, this.globalIndexId)
        let tags = document.querySelectorAll('#RadarChart path[transform]')
        for (let i = 0; i < tags.length; i++) {
          if (tags[i] == event.currentTarget)
            this.globalIndexId = i
        }
      },
      mouseOutEvent(event) {
        this.globalIndexId = null
      }
    },
    mounted(){
        this.globalIndicators = [...this.value[0].value, ...this.value[1].value]
    }
  }
</script>
  
<style>
  .echarts {
    width: 100% !important;
    height: 600px !important;
  }
</style>
  