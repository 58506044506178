<template>
  <div :class="boxCss(value.value)" style="position:relative">
    <!-- <feather-icon
        icon="DownloadIcon"
        class="top-right"
        size="24"
        @click="downloadReport(value)"
    /> -->
    <div class="about">
      {{ value.about || `About ${value.name}`  }}
    </div>
    <div class="main-content">
      <div class="box-top mt-1">
        <!-- <div v-html="getLetterGrade(value.value)"></div> -->
        <div v-if="!value.average" class="circle not-submitted">?</div>
        <div v-else-if="value.average>3.9" class="circle A">A</div>
        <div v-else-if="value.average>3.6" class="circle B">B+</div>
        <div v-else-if="value.average>3.2" class="circle B">B</div>
        <div v-else-if="value.average>2.9" class="circle B">B-</div>
        <div v-else-if="value.average>2.6" class="circle C">C+</div>
        <div v-else-if="value.average>2.2" class="circle C">C</div>
        <div v-else-if="value.average>1.9" class="circle C">c-</div>
        <div v-else-if="value.average>1.6" class="circle D">D+</div>
        <div v-else-if="value.average>1.2" class="circle D">D</div>
        <div v-else-if="value.average>0.9" class="circle D">D-</div>
        <div v-else                      class="circle F">F</div>
      </div>
      <div class="portfolio-company text-center mt-1">
        {{ value.name }}
      </div>
      <div class="d-flex justify-content-around mt-1 bottom-button">
        <div class="about-button">
          About 
          <feather-icon
            icon="InfoIcon"
            size="12"
          />
        </div>

        <div class="results-link" @click="openModal">
          Recommendations
          
          <!-- <a :style="gradeCss(value.value)" :href="`${backendUrl}/createChildReport?childId=${value.id}`">Review results</a> -->
        </div>
      </div>
    </div>

    

    <b-modal
      :id=value.name
      scrollable
      centered
      size="xl"
      title="Recommendation"
      hide-footer
    >
      <b-card-text v-if="value.recommendations && value.recommendations.length>0">
        <!-- {{ value.name }} -->
        <div class="d-flex" style="justify-content: space-evenly; font-weight: bold">
          <div>Question</div>
          <div>Answer</div>
        </div>
        <app-collapse>
          <div v-for="(recommendation, index) in value.recommendations" :key="index"> 
            <app-collapse-item :question="recommendation.question" :answer="recommendation.answer" :grade="recommendation.grade">
              <div>
                {{ recommendation.recommendation }}
              </div>
            </app-collapse-item>
          </div>
        </app-collapse>
      </b-card-text>
      <b-card-text v-else>
        You're all set
      </b-card-text>
    </b-modal>
  </div>
    
</template>

<script>
import AxiosService from '@/Service/AxiosService'
import Config from "@/Constants/Config.json"
import { BModal, VBModal, BCardText  } from 'bootstrap-vue'
import { BCol } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCardText,
    BModal,
    VBModal,
    BCol,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-modal': VBModal
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    parentId:{
      type: String
    }
  },
  data() {
    return {
    }
  },
  mounted(){
    // console.log("el", this.$el.querySelector('.about-button'))
    let parent = this.$el;

    parent.querySelector('.about-button').addEventListener('mouseenter', function() {
      parent.querySelector('.about').classList.add('about-show');
      parent.querySelector('.main-content').classList.add('opaque');
      
    })
    this.$el.querySelector('.about-button').addEventListener('mouseleave', function() {
      parent.querySelector('.about').classList.remove('about-show');
      parent.querySelector('.main-content').classList.remove('opaque');
    })
    
    
  },
  // watch:{
  //   value(val){
  //     console.log("val", val)
  //   }
  // },
  methods:{
    openModal(){
      this.$bvModal.show(this.value.name)
    },
    boxCss(value){
      // console.log("value", value)
      if(value<1) return "box-F"
      else return "box"
    },
    gradeCss(value){
      if(value<1) return "color: #FF0000"
      else return "color: #134270"
    },
    downloadReport(child){
      this.$store.dispatch("setLoader",true)

      AxiosService.downloadPdf(`/createChildReport?companyId=${child.id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        // console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${child.name} Report.pdf`
        link.click()
      })
    },
    goToResultsPage(value, parentId){
      this.$router.push({name:"Result", params:{childId:value.id, parentId:parentId, grade:value.value}})
    }
  },
  computed:{
    backendUrl(){
      return Config.backendUrl;
    }
  }
}
</script>

<style scoped>
  .box{
    border: 2px solid  #1954ec;
    color: #1954ec;
    font-weight: 600;
    padding: 10px 0 50px 0;
    /* color: #134270 !important; */
  }
  .box-F{
    border: 4px solid  #FF0000;
    font-weight: 600;
    padding: 10px 0 50px 0;
    color: #FF0000 !important;

  }
  .box-top{
    display:flex;
    justify-content: center;
  }
  .portfolio-company{
    font-size: 15px;
  }
  .circle{
    /* text-align: center; */
    font-size: 30px;
    border: 2px solid #134270;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-flex; /* you can also use 'grid' or just 'flex'*/
    justify-content: center;
    align-items: center;
  }
  .not-submitted{
    color: #aaa;
    border: 2px solid #aaa;
  }
  .A{
    color: #0000CC;
    border: 2px solid #0000CC;
  }
  .B{
    color:#008000;
    border: 2px solid #008000;
  }
  .C{
    color:#FFD700;
    border: 2px solid #FFD700;
  }
  .D{
    color:#FFA500;
    border: 2px solid #FFA500;
  }
  .F{
    color:#FF0000;
    border: 2px solid #FF0000;
  }
  .results-link{
    cursor: pointer;
    text-decoration: underline;
  }
  .top-right {
    position: absolute;
    right:20px;
    top:10px;
    font-size: 20px;
    cursor:pointer;
  }
  .about{
    transform: translateY(100%);
    opacity: 0;
    transition: 0.3s ease-in-out;
    position:absolute;
    height: 100%;
    width: 100%;
    padding:10%
  }
  .about-show {
    transform: translateY(0);
    opacity: 1;
  }
  .about-button{
    cursor:pointer;
  }
  .opaque{
    opacity: 10%;
  }
  .bottom-button{
    color:#545454 !important;
    font-family: 'Helvetica Now', Helvetica, Arial, serif;
  }
</style>