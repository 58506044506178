<template>
  <b-row>
    <b-col cols="12"
      v-if="$can('manage', 'admin')"
    >
      <div>
        PE firm
          <b-form-group>
          <v-select
            v-model="parent"
            label="name"
            :options="parentCompanies"
            
          />
        </b-form-group>
      </div>
    </b-col>
    
    <b-col cols="12" lg="10" 
      v-if="$can('read', 'parent') && parent"
    >
      <div>
        <div v-if="user.role=='parent'">Company</div>
        <div v-else>Portfolio Company</div>
          <b-form-group>
          <v-select
            v-model="child"
            label="name"
            :options="childCompanies"
          />
        </b-form-group>
      </div>
    </b-col>

    <b-col  cols="12" lg="2"
      v-if="$can('read', 'admin') && reportNotGenerated"
    >
      <b-button variant="danger" style="margin-top:20px" @click="speedUpReportGeneration">Speed up report generation</b-button>
    </b-col>
    <b-col  cols="12" lg="2"
      v-else-if="$can('read', 'parent') && error"
    >
      <b-button variant="primary" style="margin-top:20px" :disabled="true">Download {{ child.name }} Report</b-button>
    </b-col>

    <b-col  cols="12" lg="2"
      v-else-if="$can('read', 'parent') && child"
    >
      <b-button variant="primary" style="margin-top:20px" @click="downloadReport">Download {{ child.name }} Report</b-button>
    </b-col>

    

    <b-row v-if="data">
      <b-col  cols="12" class="heading" v-if="data">
        <span font-weight="bold">{{ child.name }}</span> Grade: <span :class="getGrade(data.average, true)">{{ getGrade(data.average) }}</span>
      </b-col> 

      <b-col  cols="12" v-if="value && value[0] && value.length>0 && value[0].value">
        <radarChart style="margin-top:20px" :key="data._id" :value="value" :indicator="indicator"/>
      </b-col>

      <b-col v-for="category in data.result" :key="category.name" md="3" sm = "6" ls="12" class="mt-3">
        <portfolioCompanyCategory :value="category"/>
      </b-col>
    </b-row>
    <b-row v-if="error">
      <b-col  cols="12" class="heading">
        <span font-weight="bold">{{ child.name }}</span> Grade: <span class="Z">?</span>
      </b-col>
      <b-col>
        {{ error }}
      </b-col>
    </b-row>
    <!-- <b-col md="6" sm = "12">
      <b-card v-if="data && data.childAverage" title="Average Score by Company" >
        <rose-chart :value="data.childAverage" :key="data._id"/>
      </b-card>
      <div class="average" :key="data._id">
        {{ Math.round(10*data.childTotal)/10 }}
      </div>
    </b-col>
    <b-col md="6" sm="12">
      <b-card v-if="data && data.categoryAverage" title="Average Score by Category" >
        <doughnut-chart :value="data.categoryAverage" :key="data._id"/>
      </b-card>
      
    </b-col>
    <b-col cols="12">
      <average-scores :value="data.children" :key="data._id"/>
    </b-col> -->
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BCard, BButton } from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
// import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
// import AverageScores from './AverageScores.vue'
import vSelect from 'vue-select'
// import RoseChart from '@/layouts/components/chart/rose-chart.vue'
// import DoughnutChart from '@/layouts/components/chart/doughnut-chart.vue'
import portfolioCompanyCategory from "./portfolioCompanyCategory.vue"
import AxiosService from '@/Service/AxiosService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '../../../@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Functions from '@/Constants/Functions'
import radarChart from '@/layouts/components/chart/radar-chart.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    vSelect,
    BFormGroup,
    DarkToggler,
    portfolioCompanyCategory, 
    BButton,
    radarChart
  },
  data() {
    return {
      data:null,
      parentCompanies:[],
      childCompanies:[],
      child:null,
      parent:null,
      parentAnalytics:null,
      value:[], 
      indicator:[],
      error:null,
      reportNotGenerated:false
    }
  },
  mounted(){
    // this.init();
  },
  methods:{
    init(){
      this.getParentCompany()
      // if(this.user.role=='parent')
      //   this.parent = this.user.entity;
      
      // this.getChildCompany()
      // else this.child = this.user.entity;

      // console.log("this.$route.params", this.$route.params)

      //get values from params
      // this.child = this.$route.params.childId;
      // this.parent = this.$route.params.parentId;

      // this.getChildAnalytics(this.$route.params.childId);
    },
    getChildAnalytics(childId){
      AxiosService.get("/getChildCompanyResult", {child:childId}).then(({error, message, data})=>{
          if(!data){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting companies, Please try again or contact an admin`,
              },
            })
          }
          if(error){
            this.error = error;
            this.data = null;
            if(this.error.indexOf("Report")>-1) 
              this.reportNotGenerated = true
            else 
            this.reportNotGenerated = false
            
          }
          else {
            this.reportNotGenerated = false
            this.error = null;
            this.data = data
          }
      })
    },
    getParentAnalytics(parentId){
      AxiosService.get("/getCompanyAnalytics", {parent:parentId}).then(({error, message, data})=>{
          if(!data){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: `Some error occured while getting companies, Please try again or contact an admin`,
              },
            })
          }
          else {
            this.parentAnalyticsAverage(data)
          }
      })
    },
    getParentCompany(){
      if(this.user.role == "parent"){
        this.parentCompanies[this.user.entity]
        this.parent = this.user.entity;
      }
      else 
        AxiosService.get("/companies", {"type":"parent"}).then(({error, data})=>{
          if(error){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: `Some error occured while getting companies, Please try again or contact an admin`,
                },
            })
          }
          else {
            this.parentCompanies = data.rows;
            if(!this.$route.params.parentId)
              this.parent = data.rows[0];
            // else this.parent = this.$route.params.parentId
            // find parent id in data.rows and set that as parent
            if(this.$route.params.parentId)
              this.parent = this.parentCompanies.find(company => company._id == this.$route.params.parentId)
            else 
              this.parent = this.parentCompanies[0];
          }
        })
    },
    getChildCompany(){ 
      if(!this.parent) return;

      AxiosService.get("/companies", {type:"child", parent:this.parent._id}).then(({error, message, data})=>{
          if(!data){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: `Some error occured while getting companies, Please try again or contact an admin`,
                },
            })
            return;
          } 
          // if(data.rows) companies.rows = data.rows;
          // console.log("Data", data)
          if(data.rows){
            this.childCompanies = data.rows;
            // let self = JSON.parse(JSON.stringify(this.parent));
            // // self.name = "SELF";
            // this.childCompanies.unshift(self)
            // this.child = this.childCompanies[0]

            // find child id in data.rows and set that as child
            if(this.$route.params.childId)
              this.child = this.childCompanies.find(company => company._id == this.$route.params.childId)
            else this.child = this.childCompanies[0]

            // console.log("this.child", this.child)
            
          }
      })
    },
    downloadReport(){
      this.$store.dispatch("setLoader",true)

      AxiosService.downloadPdf(`/createChildReport?companyId=${this.child._id}`).then((response)=>{
        this.$store.dispatch("setLoader",false)
        // console.log("response", response)
        if(response.error){
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  variant: 'error',
                  text: response.error,
              },
          })
        return;
        }
        const blob = new Blob([response.data], {type: 'application/pdf'})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${this.child.name} Report.pdf`
        link.click()
      })
    },
    getGrade(value, isCss){
      if(!value){
        if(isCss) return "Z"
        else return "?"
      }
      let grade = Functions.getGrade(value)
      if(!grade) return null
      if(isCss) return grade[0]
      return grade
    },
    parentAnalyticsAverage(data){
      if(data){
        // console.log("parentAnalytics", this.parentAnalytics)
        this.parentAnalytics = {
          value:[],
          name:"Portfolio Benchmark"
        };
        if(data.categoryAverage && data.categoryAverage.length){
          data.categoryAverage.forEach(cat=>{
            // console.log("cat", cat)
            this.parentAnalytics.value.push(cat.value*10)
          })
        }
        if(this.value.length==1)
          this.value.push(this.parentAnalytics)
        // console.log("this.value after", this.value, this.indicator)
      }
    },
    speedUpReportGeneration(){
      AxiosService.downloadPdf(`/speedUpReportGeneration?companyId=${this.child._id}`).then(({error, message, data})=>{
        if(data){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'info',
                    text: `Report will be available shortly`
                },
            })
            return;
          }
      })
    }
  },
  mounted() {
    this.$store.dispatch("getUser");
  },
  watch:{
    parent(){
      this.parentAnalytics = []  
      this.getChildCompany();
      this.getParentAnalytics(this.parent._id)
        
    },
    child(newVal){
      // console.log("new child", newVal)
      this.getChildAnalytics(newVal._id)
      this.value = []
      this.indicator = []
    },
    user(newVal){
      // console.log("user watch", this.user)
      if(newVal)
        this.init()
    },
    data(){
      if(this.data){
        // console.log("data", this.data)
        this.indicator = []
        let companyResult = {
          value:[],
          name:this.data.name
        };
        // console.log("this.value", this.value, this.indicator)

        if(this.data.result && this.data.result.length){
          this.data.result.forEach(cat=>{
            this.indicator.push({ name: cat.name, max: 50 },)
            companyResult.value.push(cat.average*10)
          })
        }
        this.value = []
        this.value.push(companyResult)
        this.value.push(this.parentAnalytics)
        // console.log("this.value after", this.value, this.indicator)
      } 
    },

  },
  computed:{
    user(){
        return this.$store.getters.getUser || {};
    }
  }
}
</script>

<style scoped>
  .average{
    position: absolute;
    left: calc(50% - 15px);
    top: 40%;
    z-index: 99;
    font-size: 20px;
    font-weight: bold;
  }
  .heading {
    /* color: #134270; */
    font-size: 40px;
    font-weight: 600;
  }
</style>